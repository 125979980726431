'use client';

import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';

import StepComponent from './Step.component';

import type { StepDataFragment } from '@haaretz/s-fragments/StepData';

export type StepName = StepDataFragment['name'];

export interface StepProps {
  currentStep: StepName;
}

export default function Step({ currentStep }: StepProps) {
  const variables = usePpDataVariables();

  const { data } = usePurchasePagePersonalQuery(variables, { enabled: false });

  const allSteps = data?.PurchasePage?.pageData?.allSteps;

  if (!allSteps) return null;

  return <StepComponent allSteps={allSteps} currentStep={currentStep} />;
}
