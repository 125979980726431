/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';

/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    color: color('neutral1200'),
    fontWeight: fork({
      default: 300,
      hdc: 400,
    }),
    textAlign: 'center',
    ...typesetter(0),
    ...mq({ from: 'xl', until: 'xxl', value: { ...typesetter(1) } }),
    ...mq({ from: 'xxl', value: { ...typesetter(0) } }),
  },
});

export interface PromotionsSystemNoticeProps {
  /** The text to render inside `<PromotionsSystemNotice>` */
  notice: string;
}

export default function PromotionsSystemNotice({ notice }: PromotionsSystemNoticeProps) {
  return notice ? <p className={s9(c.base)}>{notice}</p> : null;
}
