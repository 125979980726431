'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import Modal from '@haaretz/s-modal';
import * as React from 'react';
import s9 from 'style9';

import type { ModalProps } from '@haaretz/s-modal';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

const c = s9.create({
  // MODAL
  base: {
    borderStyle: 'none',
    borderTopLeftRadius: space(4),
    borderTopRightRadius: space(4),
    backgroundColor: color('neutral100'),
    paddingTop: space(6),
    paddingInlineStart: space(6),
    paddingInlineEnd: space(6),
    paddingBottom: space(8),
    overflow: 'hidden',

    '::backdrop': {
      '--bgc': color('primary1300', { opacity: 0.9 }),
      backdropFilter: 'blur(4px)',
      backgroundColor: 'var(--bgc)',
      animationName: s9.keyframes({
        '0%': { backgroundColor: 'transparent' },
        '100%': { backgroundColor: 'var(--bgc)' },
      }),
      animationPlayState: 'running',
      animationDuration: '0.25s',
      animationDirection: 'normal',
      animationFillMode: 'forwards',
    },

    ...merge({
      ...mq({
        until: 's',
        value: {
          marginBottom: 0,
          minWidth: '100%',
        },
      }),
      ...mq({
        from: 's',
        value: {
          '::backdrop': {
            '--bgc': color('neutral300', { opacity: 0.8 }),
          },
          borderRadius: radius('xxLarge'),
          width: space(125),
        },
      }),
      ...mq({
        from: 'l',
        value: {
          width: space(150),
        },
      }),
    }),
  },
  onOpenKeyFrames: {
    '--trsfrm-a': 'translateY(120%)',
    '--trsfrm-b': 'translateY(0)',
    animationName: s9.keyframes({
      '0%': { opacity: 0, transform: 'var(--trsfrm-a)' },
      '30%': { opacity: 0 },
      '100%': { opacity: 1, transform: 'var(--trsfrm-b)' },
    }),
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in-out',
    opacity: 0,

    ...merge(
      mq({
        from: 's',
        value: {
          '--trsfrm-a': 'scale(0.7)',
          '--trsfrm-b': 'scale(1)',
        },
      })
    ),
  },

  // MODAL CONTENT CSS
  closeBtnClickArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    insetInlineEnd: 0,
    position: 'absolute',
    top: 0,
  },
  closeButtonCircle: {
    backgroundColor: color('primary1000'),
    borderRadius: radius('circle'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: space(5),
    width: space(5),
  },
});

export interface PpModalProps extends Omit<React.ComponentPropsWithoutRef<'dialog'>, 'onToggle'> {
  /** The Children to be rendered inside `<PpModal>` */
  children?: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyModal(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Modal
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Modal styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /** Controls the opening and closing of the modal */
  isOpen?: boolean;
  /** A callback to update ascendant state when a modal by and action inside it */
  onToggle: ModalProps['onToggle'];
  labelledBy: ModalProps['labelledBy'];
  closeBtnOnClick: React.MouseEventHandler<HTMLButtonElement>;
  closeBtnStyle?: 'blueCircle' | 'neutral';
}
const PpModal = React.forwardRef(function PpModal(
  {
    children,
    isOpen = false,
    labelledBy,
    styleExtend = [],
    inlineStyle,
    onToggle,
    closeBtnOnClick,
    closeBtnStyle = 'blueCircle',
    ...attrs
  }: PpModalProps,
  ref: React.ForwardedRef<HTMLDialogElement>
) {
  const closeBtnBlueCircle = closeBtnStyle === 'blueCircle';
  return (
    <Modal
      onToggle={onToggle}
      isOpen={isOpen}
      styleExtend={[c.base, isOpen && c.onOpenKeyFrames, ...styleExtend]}
      inlineStyle={inlineStyle}
      {...attrs}
      labelledBy={labelledBy}
      ref={ref}
    >
      <ClickArea
        size="large"
        onClick={closeBtnOnClick}
        styleExtend={[c.closeBtnClickArea]}
        data-testid="pp-modal-close-btn"
      >
        <div className={s9(closeBtnBlueCircle && c.closeButtonCircle)}>
          <Icon icon="close" variant={closeBtnBlueCircle ? 'inverse' : 'neutral'} />
        </div>
      </ClickArea>
      {children}
    </Modal>
  );
});

export default PpModal;
