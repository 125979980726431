/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
import PromotionsSystemNotice from '@haaretz/s-pp-system-notice';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import type { StepDataFragment } from '@haaretz/s-fragments/StepData';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    marginTop: space(2),
  },
});

export interface PromotionsSystemNoticesProps {
  /** The Children to be rendered inside `<PromotionsSystemNotices>` */
  notices?: StepDataFragment['messages'];
}

export default function PromotionsSystemNotices({ notices }: PromotionsSystemNoticesProps) {
  return notices?.length ? (
    <div className={s9(c.base)} data-testid="pp-notices">
      {notices.map(notice => (
        <PromotionsSystemNotice notice={notice} key={notice} />
      ))}
    </div>
  ) : null;
}
