import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import PromotionsInstructions from '@haaretz/s-pp-instructions';
import isGqlPpStep from '@haaretz/s-pp-routing-utils/isGqlPpStep';
import Step from '@haaretz/s-pp-step';
import PromotionsSystemNotices from '@haaretz/s-pp-system-notices';
import PageTitle from '@haaretz/s-pp-title';
import * as React from 'react';
import s9 from 'style9';

import type { StepDataFragment } from '@haaretz/s-fragments/StepData';
import type { StyleExtend, NonGqlPpSteps } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    textAlign: 'center',
    marginInlineStart: 'auto',
    marginInlineEnd: 'auto',
    maxWidth: space(100),
    ...mq({
      from: 'xl',
      value: {
        marginInlineStart: 'auto',
        marginInlineEnd: 'auto',
        marginTop: space(5),
        maxWidth: space(150),
      },
    }),
  },
  headerTextArea: {
    marginTop: fork({
      default: space(3),
      hdc: space(4),
    }),
    fontFamily: fork({ default: fontStack('primary'), hdc: fontStack('secondary') }),
    ...merge({
      ...mq({
        until: 'm',
        value: {
          marginTop: fork({
            default: space(1),
            hdc: space(4),
          }),
        },
      }),
    }),
  },
  ppTitle: fork({
    default: {},
    hdc: { marginBottom: space(1) },
  }),
});

export interface PpHeaderProps {
  /** The Children to be rendered inside `<PpHeader>` */
  /** The Current Step of the user, can be chosen out of these steps. */
  currentStep: StepDataFragment['name'] | NonGqlPpSteps;
  /** The title of the Promotion page  */
  title?: StepDataFragment['title'];
  /** instructions, will be set as a string || undefined*/
  instructions?: StepDataFragment['instructions'];
  /** notices will be set as an array of strings. */
  messages?: StepDataFragment['messages'];
  /** styleExtend  */
  styleExtend?: StyleExtend;
}

export default function PpHeader({
  currentStep,
  title,
  instructions,
  messages,
  styleExtend = [],
}: PpHeaderProps) {
  return (
    <header className={s9(c.base, ...styleExtend)}>
      {isGqlPpStep(currentStep) ? <Step currentStep={currentStep} /> : null}
      <div className={s9(c.headerTextArea)}>
        {title ? <PageTitle styleExtend={[c.ppTitle]}>{title}</PageTitle> : null}
        <PromotionsInstructions instructions={instructions} />
        <PromotionsSystemNotices notices={messages} />
      </div>
    </header>
  );
}
